import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  KeyboardEvent
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import { useHttp } from '../../hooks'

import {
  config,
  Translater
} from '../../config'

import {
  IPagination,
  IFilter,
  TSortBy,
  IProduct, IRestaurant, ICategory
} from '../../types/items'
import { SearchSelect } from "../../components/Page";


const IndexPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, totalCount, request } = useHttp()

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'updated',
    sortOrder: 'desc',
  })
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
  const [searchRest, setSearchRest] = useState<string>('')
  const [searchCategory, setSearchCategory] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [categories, setCategories] = useState<ICategory[]>([]);

  const [primary, setPrimary] = useState<IProduct[]>([])

  const Utils = {
    findCategoryById: () => {
      const elem = categories.find((item) => (item._id === searchCategory))
      if (elem) {
        const result = elem?.title
        return result;
      }

      return '';

    },
    findTitleRestById: () => {
      const elem = restaurants.find((item) => item._id === searchRest)

      if (elem) {
        const result = elem?.title
        return result
      }
      else {
        return searchRest[0]
      }
    }
  };


  const Events = {
    searchHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value)
    },
    pressHandler: (e: KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.Fetch()
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
    sortHandler: (name: TSortBy) => {
      let sortOrder: 'asc' | 'desc'
      if (filter.sortOrder === 'asc') sortOrder = 'desc'
      else sortOrder = 'asc'
      setFilter({
        sortBy: name,
        sortOrder
      })

      setPagination({ ...pagination, page: 1 })
    },
    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    },
    optionHandler: (option: ICategory) => {
      option._id && setSearchCategory(option._id)
    },
    clearFilterHandler: () => {
      setSearchCategory('');
    }
  }

  const Callbacks = {
    FetchProductsByCategory: async () => {
      try {
        if (searchCategory) {
          const response: IProduct[] = await request(`${config.API}/products/${searchCategory}/byCategory?full=1&page=${pagination.page}&perPage=${pagination.perPage}
          &children=${pagination.children}&name=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`, 'GET', null, {
            Authorization: (token as string)
          })

          response && setPrimary(response);
        }

      }
      catch (e) {
        console.log("E", e)
      }
    },
    FetchCategories: async () => {
      try {
        const response: ICategory[] = await request(`${config.API}/categories/`, 'GET', null, {
          Authorization: (token as string)
        })

        response && setCategories(response)
      }
      catch (e) {
        console.log("E", e)
      }
    },
    Fetch: async () => {
      try {

        function createUrl() {
          const url = `${config.API}/products/?full=1&restaurant[]=`

          const string = searchRest && replaceAll(searchRest?.toString(), ',', '&restaurant[]=')

          function replaceAll(string: string, search: string, replace: string): string {
            return string.split(search).join(replace);
          }
          return url + string
        }

        if (searchRest.length === 0) {

          const response: IProduct[] = await request(
            `${config.API}/products/?full=1&page=${pagination.page}&perPage=${pagination.perPage}
            &children=${pagination.children}&name=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
            'GET', null, {
            Authorization: (token as string)
          });
          if (response) {
            setPrimary(response)
          }
        } else {


          const response: IProduct[] = await request(
            createUrl() + `&page=${pagination.page}&perPage=${pagination.perPage}&children=${pagination.children}&name=${searchValue
            }&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
            'GET', null, {
            Authorization: (token as string)
          })
          if (response) {
            setPrimary(response)
          }
        }


      } catch (e) {
        console.log("ERROR", e)
      }
    },
    FetchRestaurants: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants/`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )
        if (response) {
          setRestaurants(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
  }
  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  useEffect(() => {
    Callbacks.Fetch()
  }, [filter.sortBy, filter.sortOrder, pagination.page, pagination.perPage, searchRest])

  useEffect(() => {
    Callbacks.FetchRestaurants()
    Callbacks.FetchCategories();
  }, [])


  useEffect(() => {
    searchCategory ?
      Callbacks.FetchProductsByCategory() : Callbacks.Fetch();
  }, [searchCategory]);


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={Translater.ProductsPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.ProductsPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.products?.rule === 'change'}
        linkTo='/products/create'
      />


      {/* Pick Restaurant */}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
        <SearchSelect height={47} width={260} data={categories} placeholder={Translater.Placeholders.filterByCategory[language.slug]} value={searchCategory ? searchCategory : null}
          searchField='title'
          setSwitch={Events.optionHandler}
        />


        <button
          className='btn btn--default btn--default--green clear-filter'
          onClick={Events.clearFilterHandler}
        >
          {Translater.Items.Clear[language.slug]}
        </button>



        <Page.SortBar
          searchBar
          filter={filter}
          value={searchValue}
          perPage={pagination.perPage}
          pressHandler={Events.pressHandler}
          inputHandler={Events.searchHandler}
          selectHandler={Events.selectHandler}

        />

      </div>


      <Components.Table
        items={primary}
        sortHandler={Events.sortHandler}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo='/products'
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          setPagination={setPagination}
        />
      </Page.Footer>

    </Page.Wrapper >
  )
}

export default IndexPage
