import React from 'react'
import { IInputProps } from './types'

const Input: React.FC<IInputProps> = ({
  type = 'text',
  name,
  label,
  value,
  multiple,
  placeholder,
  isValid = true,
  inputHandler,
  pressHandler = () => { },
  onFocus,
  addClassName, pattern
}) => {
  return (
    <div className='input-wrapper'>
      {label ? <h6 className='input-label'>{label}</h6> : null}

      <input
        type={type}
        pattern={pattern}
        name={name}
        value={value}
        multiple={multiple}
        placeholder={placeholder}
        className={`input ${!isValid && 'invalid'} ${addClassName ? addClassName : ''}`}
        onChange={inputHandler}
        onKeyPress={pressHandler}
        onFocus={onFocus}
        accept={type === 'file' ? 'image/*' : undefined}
      />
    </div>
  )
}

export default Input
