import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'

import { NavLink } from 'react-router-dom'
import { Translater } from '../../config'
import { INavLink } from '../Page/types'
import { Feedback } from '../../pages/Products/components'

const CustomNavLink: React.FC<INavLink> = ({ slug, linkTo, clickHandler }) => {
  const { modules } = useContext(Contexts.ConfigContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const [localAccess, setLocalAccess] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [image, setImage] = useState<any>(
    require('../../assets/icons/home.svg')
  )

  useEffect(() => {
    switch (slug) {
      case 'home':
        if (
          modules.admin &&
          access.admin?.rule &&
          access.admin?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/home.svg'))
          setTitle(Translater.Sidebar.home[language.slug])
        }
        break
      case 'orders':
        if (
          modules.orders &&
          access.orders?.rule &&
          access.orders?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/order.svg'))
          setTitle(Translater.Sidebar.orders[language.slug])
        }
        break
      case 'categories':
        if (
          modules.categories &&
          access.categories?.rule &&
          access.categories?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/categories.svg'))
          setTitle(Translater.Sidebar.categories[language.slug])
        }
        break
      case 'restaurants':
        if (
          modules.restaurants &&
          access.restaurants?.rule &&
          access.restaurants?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/restaurant.svg'))
          setTitle(Translater.Sidebar.restaurants[language.slug])
        }
        break
      case 'products':
        if ( 
          modules.products &&
          access.products?.rule &&
          access.products?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/products.svg'))
          setTitle(Translater.Sidebar.products[language.slug])
        }
        break
      case 'options': 
        if ( 
          modules.products &&
          access.products?.rule &&
          access.products?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/options.svg'))
          setTitle(Translater.Sidebar.options[language.slug])
        }
        break
      /* case 'feedback':
        if (
          (modules.products as any)?.feedback
          && access.products?.children?.feedback?.rule
          && access.products?.children?.feedback?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/feedback.png'))
          setTitle(Translater.Sidebar.feedback[language.slug])
        }
        break */
      // case 'sales':
      //   if (false) { //!fix rule from backend //TODO create
      //     setLocalAccess(true)
      //     setImage(require('../../assets/icons/sale.png'))
      //     setTitle(Translater.Sidebar.sales[language.slug])
      //   }
      //   break
      case 'news': 
        if (
          (modules.admin as any)?.roles &&
          access.admin?.children?.roles?.rule &&
          access.admin?.children?.roles?.rule !== 'false'
        ) {
          console.log(modules);
          setLocalAccess(true)
          setImage(require('../../assets/icons/news.svg'))
          setTitle(Translater.Sidebar.news[language.slug])
        }
        break 
      case 'stocks':
        if (
          modules.stocks &&
          access.stocks?.rule &&
          access.stocks?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/sale.svg'))
          setTitle(Translater.Sidebar.stocks[language.slug])
        }
        break
      case 'users':
        if (
          modules.users &&
          access.users?.rule &&
          access.users?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/consumer.svg'))
          setTitle(Translater.Sidebar.users[language.slug])
        }
        break
      case 'usersBirthdays':
        if (
          modules.users &&
          access.users?.rule &&
          access.users?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/birthday.svg'))
          setTitle(Translater.Sidebar.birthdays[language.slug])
        }
        break
      case 'staff':
        if (
          (modules.admin as any)?.staff &&
          access.admin?.children?.staff?.rule &&
          access.admin?.children?.staff?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/staff.svg'))
          setTitle(Translater.Sidebar.staff[language.slug])
        }
        break
      case 'roles':
        if (
          (modules.admin as any)?.roles &&
          access.admin?.children?.roles?.rule &&
          access.admin?.children?.roles?.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/role.svg'))
          setTitle(Translater.Sidebar.roles[language.slug])
        }
        break
      case 'history':
        if (
          (modules.orders as any)?.history &&
          access.orders?.children?.history &&
          access.orders?.children?.history.rule !== 'false'
        ) {
          setLocalAccess(true)
          setImage(require('../../assets/icons/archive.svg'))
          setTitle(Translater.Sidebar.history[language.slug])
        }
        break
      case 'notifications':
        setLocalAccess(true)
        setImage(require('../../assets/icons/notifications.svg'))
        setTitle(Translater.Sidebar.notifications[language.slug])
        break
      case 'booking':
        setLocalAccess(true)
        setImage(require('../../assets/icons/bookingList.svg'))
        setTitle(Translater.Sidebar.booking[language.slug])
        break
      case 'bookingDetails':
        setLocalAccess(true)
        setImage(require('../../assets/icons/booking.svg'))
        setTitle(Translater.Sidebar.bookingDetails[language.slug])
        break
      case 'referals':
        setLocalAccess(true)
        setImage(require('../../assets/icons/referal.svg'))
        setTitle(Translater.Sidebar.referals[language.slug])
        break
      case 'delivery':
        setLocalAccess(true)
        setImage(require('../../assets/icons/delivery.svg'))
        setTitle(Translater.Sidebar.delivery[language.slug])
        break
      case 'settings':
        setLocalAccess(true)
        setImage(require('../../assets/icons/settings.svg'))
        setTitle(Translater.Sidebar.settings[language.slug])
        break
      default:
        setLocalAccess(false)
    }
  }, [language])

  if (!localAccess) return null

  return (
    <NavLink className='sidebar-link' to={linkTo} onClick={clickHandler}>
      <img alt={slug} src={image} />
      {title}
    </NavLink>
  )
}

export default CustomNavLink
