import React from "react";
import * as Page from "../../../../components/Table";
import { Options } from "./static";
import { TableProps } from "./types";
import Track  from "./Track";

const Table: React.FC<TableProps> = ({
    data,
    Events,
}) => {
    return (
        <Page.Table
            type="options"
            titles={Options}
        >
            {data?.map((item) => (
                <Track key={item._id} data={item} Events={Events} />
            ))}
        </Page.Table>
    )
}

export default Table;
