import React, {
    useState,
    useEffect,
    useContext, ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'

import { useHttp } from '../../hooks'

import {
    config,
    Translater
} from '../../config'
import * as Components from "./components";
import { IPagination, IRestaurant } from '../../types/items'
import { IFilter } from '../../types/items'


const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { loading, request, totalCount } = useHttp()

    const [primary, setPrimary] = useState<any>([])
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        perPage: 10,
        totalCount: 0,
    })
    const [searchValue, setSearchValue] = useState<string>('')
    const [filter, setFilter] = useState<IFilter>({
        sortBy: 'updated',
        sortOrder: 'desc',
    })

    const Events = {
        reloadHandler: () => {
            Callbacks.Fetch()
        },

        paginateHandler: (page: number) => {
            setPagination({ ...pagination, page });
        },

        selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setPagination({ ...pagination, page: 1, perPage: +e.target.value })
        },
        inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value);
        },
        pressHandler: (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') Callbacks.Fetch()
        },

    }

    const Callbacks = {
        Fetch: async () => {
            try {

                const response: any = await request(`${config.API}/booking?page=${pagination.page}&perPage=${pagination.perPage}`,
                    'GET', null, {
                    Authorization: (token as string)
                });
                const restaurants: IRestaurant[] = await request(`${config.API}/restaurants`, 'GET', null, {
                    Authorization: token as string
                })

                if (response) {
                    console.log(response);
                    const cnv: any[] = [];
                    for (let book of response) {
                        restaurants.forEach((i: IRestaurant) => {
                            if (i._id == book.restaurant) {
                                cnv.push({ ...book, restaurant: i.title });
                            }
                        })
                    }
                    setPrimary(cnv);
                }
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [pagination.perPage, pagination.page])

    useEffect(() => {
        setPagination({ ...pagination, totalCount });
    }, [totalCount]);

    if (loading || !primary) return <Page.Preloader />

    return (
        <Page.Wrapper


            title={Translater.BookingPage.title[language.slug]}
        >


            <Page.Header
                title={Translater.BookingPage.title[language.slug]}
                linkTitle={Translater.Buttons.create[language.slug]}
                backButtonLink='/booking'
            />
            {/* 
    <Page.SortBar
                searchBar
                value={searchValue}
                perPage={pagination.perPage}
                inputHandler={Events.inputHandler}
                selectHandler={Events.selectHandler}
                pressHandler={Events.pressHandler}
            /> */}

            <Components.Table
                items={primary}
                reloadHandler={Events.reloadHandler}
            />

            <Page.Footer>
                <Page.Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    paginateHandler={Events.paginateHandler}
                    linkTo='/booking'
                />
            </Page.Footer>

        </Page.Wrapper>
    )
}

export default IndexPage
