import React, { useContext, useEffect, useState } from 'react'
import { IConfigBlockProps } from './types'
import * as Form from '../../../components/Form'
import { Translater } from '../../../config'
import * as Contexts from '../../../contexts'
import { config } from '../../../config'
import { useHttp } from '../../../hooks'
import { IRestaurant } from '../../../types/items'
import * as Page from '../../../components/Page'
import { useParams } from 'react-router-dom'
import { IForm } from '../types'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  setForm,
  setRest,
}) => {
  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()
  const { id }: any = useParams()

  const [rests, setRests] = useState<IRestaurant[]>([])
  const [addition, setAddition] = useState<IForm>();
  const [restaurant, setRestaurant] = useState<IRestaurant[]>();
  // const [rest, setRest] = useState<any>({});
  const Events = {
    inputHandler: (e: any) => {
      const name = e.target.name
      const value = e.target.value
      //@ts-ignore
      setForm((state: any) => ({ ...state, [name]: value }))
    },

    selectHandler: (e: any) => {
      for (let rest of rests) {
        if (rest.title == e.target.value) {
          //@ts-ignore
          setForm((state: any) => ({ ...state, restaurant: rest._id }))
          setRest(rest)
        }
      }
    },
    photoHandler: (e: any) => {
      if (e.target?.files[0] !== null) {
        setForm({ ...form, photo: e.target?.files[0] })
      }
    },
  }

  const Callbacks = {
    GetRests: async () => {
      const rests = await request(`${config.API}/restaurants`, 'GET', null, {
        Authorization: token as string,
      })
      if (rests.length) {
        setRest(rests[0])
        setRests(rests)
        //@ts-ignore
        setForm((state: any) => ({ ...state, restaurant: rests[0]._id }))
      }
    },
    getAddition: async () => {
      try {
        const Authorization = token as string
        if (!id) return;
        const addition = await request(
          `${config.API}/addition/${id}`,
          'GET',
          null,
          {
            Authorization,
          }
        )

        setAddition(addition)
      } catch (e) {
        console.log(e)
      }
    },
    getRestaurantById: async (id: string) => {
      try {
        const Authorization = token as string

        if (!id) return;
        const data = await request(
          `${config.API}/restaurants/${id}`,
          'GET',
          null,
          {
            Authorization,
          }
        )

        setRestaurant(data);


      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.GetRests()
  }, [])
  useEffect(() => {
    Callbacks.getAddition()
  }, [])


  useEffect(() => {


    if (!restaurant) {
      let id = form.restaurant;
      if (Array.isArray(id)) {
        Callbacks.getRestaurantById(id[0]);
        return;
      }
    }



  });


  useEffect(() => {
    setForm && setForm({
      ...form, restaurant: Array.isArray(restaurant) && restaurant?.map(element => {
        return element._id;
      }) || []
    });
  }, [restaurant]);

  return (
    <div>

      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.options.title[language.slug]}
      >
        <Form.Input
          type='text'
          name='name'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          // isValid={isValid.title}
          value={form.name}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.options.price[language.slug]}
      >
        <Form.Input
          name='price'
          type='number'
          placeholder={'0'}
          // isValid={isValid.title}
          value={form.price}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.options.photo[language.slug]}
      >
        {form.photo && (typeof form.photo === 'string' || form.photo instanceof String) && (<img
          alt='Category'
          src={`${config.serverURL}${form.photo}`}
          style={{ objectFit: 'cover', width: 350, height: 250 }}
        />)}
        <Form.Input
          type='file'
          name='photo'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          // isValid={isValid.title}
          inputHandler={Events.photoHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.g[language.slug]}
      >
        <Form.Input
          name='weight'
          type='number'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          // isValid={isValid.title}
          inputHandler={Events.inputHandler}
          value={form.weight}
        />
      </Form.LabelField>

      {/* {rests && (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.restaurant[language.slug]}
        >
          <>

            <Page.SearchSelect
              isMulti
              placeholder='Выберите ресторан'
              searchField='title'
              data={rests}
              multiField='_id'
              setSwitch={(restaurant: any) =>
                setForm && setForm({ ...form, restaurant: restaurant })
              }
              value={restaurant}
            />
          </>
        </Form.LabelField>
      )} */}
    </div>
  )
}

export default ConfigBlock
