import React, { FC, Fragment, useContext } from 'react'

import { LanguageContext, UserContext } from '../../../contexts'

import { TContactsBlockProps } from './types'
import { Input, LabelField } from '../../../components/Form'
import { Translater } from '../../../config'

const ContactsBlock: FC<TContactsBlockProps> = ({
  form,
  isValid,

  inputHandler,
}) => {
  const { language } = useContext(LanguageContext)
  const { access } = useContext(UserContext)

  return (
    <Fragment>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.email[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.email : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='email'
            value={form.email}
            isValid={isValid.email}
            placeholder={Translater.Placeholders.minThree[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>

      <LabelField
        fontSize='small'
        label={Translater.TableTitles.phone[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.phone : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='phone'

            value={form.phone}
            isValid={isValid.phone}
            placeholder={Translater.Placeholders.minThree[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>

      <LabelField
        fontSize='small'
        label={Translater.TableTitles.license[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.license : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='license'
            value={form.license}
            isValid={isValid.license}
            placeholder={Translater.Placeholders.minThree[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>

      <LabelField
        fontSize='small'
        label={Translater.TableTitles.facebook[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.facebook : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='facebook'
            value={form.facebook}
            isValid={isValid.facebook}
            placeholder={Translater.Placeholders.tapsLinks[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>

      <LabelField
        fontSize='small'
        label={Translater.TableTitles.instagram[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.instagram : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='instagram'
            value={form.instagram}
            isValid={isValid.instagram}
            placeholder={Translater.Placeholders.tapsLinks[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>

      <LabelField
        fontSize='small'
        label={Translater.TableTitles.addressLabel[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.label : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='label'
            value={form.label}
            isValid={isValid.label}
            placeholder={Translater.Placeholders.minSix[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>

      <LabelField
        fontSize='small'
        label={Translater.TableTitles.longitude[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.longitude : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='longitude'
            pattern='^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$'
            value={form.longitude}
            isValid={isValid.longitude}
            placeholder={Translater.Placeholders.tapsLng[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>

      <LabelField
        fontSize='small'
        label={Translater.TableTitles.latitude[language.slug]}
        text={access.restaurants?.rule !== 'change' ? form.latitude : ''}
      >
        {access.restaurants?.rule === 'change' ? (
          <Input
            name='latitude'
            pattern='^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$'
            value={form.latitude}
            isValid={isValid.latitude}
            placeholder={Translater.Placeholders.tapsLat[language.slug]}
            inputHandler={inputHandler}
          />
        ) : null}
      </LabelField>
    </Fragment>
  )
}

export default ContactsBlock
