import React, { Fragment, useState, useEffect, useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Components from '.'

import { useHttp } from '../../../../hooks'
import { config } from '../../../../config'
import { IRules } from '../../../../types/items'
import { IRootProps, TPossibleRuleSlugs } from './types'

const Root: React.FC<IRootProps> = ({ form, setForm }) => {
  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [configuration, setConfig] = useState<any>({})
  const fieldsOrder = ['orders', 'categories', 'products', 'addition', 'booking', 'restaurants', 'users', 'delivery', 'stocks', 'news', 'admin'];
  const Callbacks = {
    Fetch: async () => {
      try {
        const response: any = await request(
          `${config.API}/roles/configuration`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )
        if (response.children) {
          delete response.children.bonusesHistory;
          delete response.children.referals;
          console.log("REOISNE", response.children);
          setConfig(response.children)
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])
  return (
    <Fragment>
      {console.log("Test", configuration[fieldsOrder[0]])
      }
      {fieldsOrder.map((item) => (

        <>

          {fieldsOrder.includes(item) && configuration[item] && (
            <Components.Field
              key={item}
              nesting={0}
              form={form}
              parent={null}
              //@ts-ignore
              item={configuration[item]}
              //@ts-ignore
              slug={item}
              setForm={setForm}
            />

          )}

        </>
      ))
      }
    </Fragment >
  )
}

export default Root
