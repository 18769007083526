import { ITableTitle } from './../../../../types/table'

export const SHomeTitles: ITableTitle[] = [
  { type: 'header', slug: 'increment', sort: true },
  { type: 'header', slug: 'user' },
  { type: 'header', slug: 'delivery' },
  { type: 'header', slug: 'comment', sort: true },
  { type: 'header', slug: 'status' },
  { type: 'header', slug: 'itemsTotalPrice', sort: true },
  { type: 'header', slug: 'archived', sort: true },
  { type: 'header', slug: 'created' },
  { type: 'header', slug: '' },
]