import React, {
  useState,
  useContext,
  ChangeEvent,
  useMemo,
  useEffect,
} from 'react'

import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks'
import { config, Translater } from '../../config'

import { IProduct, IOptions, IRestaurant } from '../../types/items'
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions,
} from '../../types/multiLang'
import { useHistory } from 'react-router-dom'
import { TCreateValidationSchema } from './types'
import { useValidation } from '../../hooks'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { multiLang, modules } = useContext(Contexts.ConfigContext)
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([])

  const { loading, request } = useHttp()
  const history = useHistory()

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    category: false,
    description: false,
    weight: false,
  })

  const [form, setForm] = useState<IProduct>({
    name: '',
    price: '0',
    photo: '',
    parent: '',
    hidden: 'false',
    category: '',
    restaurant: [],
    description: '',
    options: [],
    weight: '0',
    novelty: 'false',
    additions: [],
    variations: [],
    isVariated: false
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      name: {
        condition: form.name.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.title[language.slug]}`,
      },
      weight: {
        condition: (parseInt(form.weight) > 0 && (form.isVariated == 'false' || !form.isVariated)) || ((form.isVariated && form.isVariated != 'false') || form.isVariated == 'true'),
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.weight[language.slug]}`,
      },

      price:
      {
        condition: (form.price > 0 && (form.isVariated == 'false' || !form.isVariated)) || ((form.isVariated && form.isVariated != 'false') || form.isVariated == 'true'),
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.price[language.slug]}`,
      },
      description: {
        condition: form.description.length >= 2,
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
      category: {
        condition: !!form.category,
        error: ` ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.category[language.slug]}
        `,
      },
      photo: {
        condition: !!form.photo,
        error: Translater.ErrorPhoto[language.slug],
      },
      variations:
      {
        condition: (((form.isVariated && form.isVariated != 'false') || form.isVariated == 'true') && form.variations.length) || (form.isVariated == 'false' || !form.isVariated),
        error: Translater.ErrorVariations[language.slug],
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const [optionValue, setOptionValue] = useState<any>({
    option: '',
    photo_option: '',
    price_option: '0',
    additions: '',
  })

  const [options, setOptions] = useState<IOptions[]>([])

  const [similar, setSimilar] = useState<string[]>([])

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    'title[EN]': '',
    'title[RU]': '',
    'title[UA]': '',
  })

  const [multiLangDescriptions, setMultiLangDescriptions] =
    useState<TMutliLangDescriptions>({
      'description[EN]': '',
      'description[RU]': '',
      'description[UA]': '',
    })

  const Events = {
    inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value

      //@ts-ignore
      if (type === 'file')
        //@ts-ignore
        setOptionValue({ ...optionValue, photo_option: e.target.files[0] })
      else {
        setOptionValue({ ...optionValue, [name]: value })
      }
    },
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value

      //@ts-ignore
      if (type === 'file') setForm({ ...form, photo: e.target.files[0] })
      else if (type === 'number') {
        if (+value >= 0) setForm({ ...form, [name]: value })
      } else if (name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [name]: value,
        })
      } else setForm({ ...form, [name]: value })
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name.split('[')[1]) {
        setMultiLangDescriptions({
          ...multiLangDescriptions,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    buttonOptionHandler: () => {
      setOptions([...options, optionValue])
      setOptionValue({
        option: '',
        photo_option: '',
        price_option: '0',
        additions: '',
      })
    },
    setHandler: (arr: string[]) => {
      setSimilar(arr)
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setCategory: (category: string) => {
      setForm({ ...form, category })
    },
    setRestaurant: (restaurant: string[]) => {
      setForm({ ...form, restaurant })
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log(e)
      }
    },
  }

  const Callbacks = {
    Save: async () => {
      try {
        const data = new FormData()

        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[key as TPossibleMultiLangTitle])
          }
        } else data.append('name', form.name)

        if (multiLang) {
          for (let key in multiLangDescriptions) {
            data.append(
              key,
              multiLangDescriptions[key as TPossibleMultiLangDescriptions]
            )
          }
        } else data.append('description', form.description)

        if (form.photo) data.append('photo', form.photo)
        data.append('isVariated', form.isVariated as string)

        data.append('price', form.price as string)
        data.append('hidden', form.hidden as string)
        data.append('novelty', form.novelty as string)
        data.append('category', form.category._id);
        restaurants[0] && data.append("restaurant[]", restaurants[0]._id);

        if (form.parent) data.append('parent', form.parent as string)
        data.append('weight', form.weight as string)
        const addsArray: any[] = [];
        for (let ad of form.additions) {
          addsArray.push(ad._id)
        }

        for (let i = 0; i < addsArray.length; i++) {
          data.append('additions[]', JSON.stringify(addsArray[i]) as any)
        }

        for (let varData of form.variations) {
          data.append('variations[]', JSON.stringify(varData));
        }


        const response = await request(
          `${config.API}/products`,
          'POST',
          data,
          {
            Authorization: token as string,
          }
        )
        history.push('/products')
      } catch (e) {
        console.log(e)
      }
    },
    FetchRestaurants: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants/`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )
        if (response) {
          setRestaurants(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
  }
  useEffect(() => {

    Callbacks.FetchRestaurants();
  }, [])
  return (
    <Page.Wrapper title={Translater.ProductsCreatePage.title[language.slug]}>

      <Page.Header
        backButtonTitle={Translater.ProductsCreatePage.title[language.slug]}
        backButtonLink='/products'
      />

      <Buttons.Container
        disabled={loading}
        createHandler={
          access.products?.rule === 'change' ? Events.saveHandler : false
        }
      />

      <Components.ConfigBlock
        form={form}
        setForm={setForm}
        optionValue={optionValue}
        options={options}
        isValid={validationSchema}
        multiLangTitles={multiLangTitles}
        multiLangDescriptions={multiLangDescriptions}
        setCategory={Events.setCategory}
        setRestaurant={Events.setRestaurant}
        inputHandler={Events.inputHandler}
        inputOptionHandler={Events.inputOptionHandler}
        buttonOptionHandler={Events.buttonOptionHandler}
        selectHandler={Events.selectHandler}
        textareaHandler={Events.textareaHandler}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>

      {/* access.products?.children?.similar?.rule &&
      access.products?.children?.similar?.rule === 'change' &&
      (modules.products as any).similar ? (
        <Components.SimilarBlock setHandler={Events.setHandler} />
      ) : null */}
    </Page.Wrapper>
  )
}

export default CreatePage
