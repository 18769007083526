import { useState, useCallback, useContext } from 'react'

import * as Context from '../contexts'

import { TRequestHookProps } from './types'

export const useHttp = () => {
  const { actions: authActions } = useContext(Context.AuthContext)

  const [loading, toggleLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [errors, setErrors] = useState<any>(null)

  const request = useCallback<TRequestHookProps>(
    async (url, method = 'GET', body = null, headers = {}) => {
      try {
        toggleLoading(true)

        if (body instanceof FormData) {

        } else if (body) {
          body = JSON.stringify(body)
          headers['Content-Type'] = 'application/json'
        }


        const response = await fetch(url, {
          method,
          body,
          headers,
        });


        let fetchedTotalCount: string | null = await response.headers.get(
          'Total-Count'
        )

        if (fetchedTotalCount) setTotalCount(+fetchedTotalCount)

        const data = await response.json()

        if (response.status === 401) {
          authActions.LOGOUT()
          setErrors(data.message)
          toggleLoading(false)
          return false
        }

        if (!response.ok) {
          throw new Error(data.message || 'Request has failed')
        }

        toggleLoading(false)

        return data
      } catch (e) {
        toggleLoading(false)
        setErrors(e)
        throw e
      }
    },
    []
  )

  const clearErrors = useCallback(() => setErrors(null), [])

  return { loading, errors, totalCount, request, clearErrors }
}
