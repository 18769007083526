import React, { useState, useEffect, useContext, ChangeEvent, useMemo } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from "../../components/Buttons"

import { useHttp } from '../../hooks'

import { config, Translater } from '../../config'
import { Editor } from '../../components/Editor/Editor'

import * as Form from '../../components/Form'
import { IRestaurant } from '../../types/items';
import { IDelivery, IRemoteArea, TSchemaDelivery } from "./types";
import { useValidation } from '../../hooks'
import { CardMedia, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()
  const [rests, setRests] = useState<IRestaurant[]>([]);
  const [restId, setRestId] = useState<IRestaurant | null>(null);

  const [control, setControl] = useState<boolean>(false);
  const [primary, setPrimary] = useState<any>({})
  const [percents, setPercents] = useState<string>("0");
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const DefaultIForm = (): IDelivery => {
    return {
      restId: '',
      priceToDoor: 0,
      priceToHome: 0,
      freeDeliverySum: 0,
      toRemoteAreas: [],
    }
  }

  const DefaultIRemoteArea = (): IRemoteArea => {
    return {
      price: 0,
      name: '',
      startRange: 0,
      endRange: 0,
    }
  }

  const GenerateTextFromTo = (from: number, to: number): string => {
    return `From ${from / 1000}km to ${to / 1000}km`;
  }

  const [remoteArea, setRemoteArea] = useState<IRemoteArea>(DefaultIRemoteArea());
  const [form, setForm] = useState<IDelivery>(DefaultIForm())

  const schema = useMemo<TSchemaDelivery>(
    () => ({
      freeDeliverySum: {
        condition: form.freeDeliverySum > 0,
        error: `${Translater.ErrorLength[language.slug]}: ${Translater.TableTitles.freeDeliverySum[language.slug]}`,
      },

      priceToHome: {
        condition: form.priceToHome > 0,
        error: `${Translater.ErrorLength[language.slug]}: ${Translater.TableTitles.priceToHome[language.slug]}`,
      },

      priceToDoor: {
        condition: form.priceToDoor > 0,
        error: `${Translater.ErrorLength[language.slug]}: ${Translater.TableTitles.priceToDoor[language.slug]}`,
      },

      toRemoteAreas: {
        condition: form.toRemoteAreas.length > 0,
        error: `${Translater.ArrayErrorLength[language.slug]}: ${Translater.Buttons.controlPoints[language.slug]}`,
      },
    }), [form]
  );

  const { errors, validationSchema, validation } = useValidation(schema);

  const Events = {
    inputHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const name = e.target.name
      const value = e.target.value

      setForm({ ...form, [name]: value })
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },

    reloadHandler: () => {
      Callbacks.Fetch()
    },

    buttonHandler: () => {
      Callbacks.SaveData()
    },

    selectRestHandler: (rest: any) => {
      setRestId(rest);
      setForm({ ...form, restId: rest._id })
      setControl(false);
      setRemoteArea(DefaultIRemoteArea());
    },

    inputRemoteAreaHandler: (e: any) => {
      setRemoteArea({ ...remoteArea, [e.target.name]: !e.target.value.toString().includes('-') ? e.target.value : 0 });
    },

    addRemoteAreaHandler: () => {
      if (remoteArea.endRange > 0 && remoteArea.price > 0) {
        const lastRemote = form.toRemoteAreas[form.toRemoteAreas.length - 1];
        const startRange = +(form.toRemoteAreas?.length > 0 ? lastRemote.endRange : 0);
        const endRange = +remoteArea.endRange * 1000;
        const remote = {
          ...remoteArea,
          price: +remoteArea.price,
          name: GenerateTextFromTo(startRange, endRange),
          startRange,
          endRange,
        };

        setRemoteArea(DefaultIRemoteArea());

        if (endRange <= startRange || +lastRemote?.price >= +remoteArea.price) {
          return;
        };

        setForm({ ...form, toRemoteAreas: [...form.toRemoteAreas, remote] });
      }
    },

    deleteRemoteAreaHandler: (name: string) => {
      if (form.toRemoteAreas.length > 1) {
        setForm({ ...form, toRemoteAreas: form.toRemoteAreas.filter((i) => i.name != name) });
      }
    },
  }

  const GetRests = async () => {
    try {
      const response: IRestaurant[] = await request(
        `${config.API}/restaurants`,
        'GET',
        null,
        {
          Authorization: token as string,
        }
      )

      return response;
    } catch (e) {
      console.log(e)
    }
  }

  const Callbacks = {
    loadRestaurantOptions: async () => {
      try {
        const response = await GetRests();

        if (response) {
          setRests(response);
          setRestId(response[0])
        };
      } catch (e) {
        console.log(e)
      }
    },

    Fetch: async () => {
      try {
        setForm(DefaultIForm());
        const response: any = await request(
          `${config.API}/delivery/${restId ? restId._id : ''}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        const rests = await GetRests();

        if (!response.toRemoteAreas) {
          setForm({ ...response, toRemoteAreas: [] });
          setPrimary({ ...response, toRemoteAreas: [] });
        } else {
          setForm(response);
          setPrimary(response);
        }
        if (rests) {
          const percents = rests.find((i) => i._id == response.restId)?.percents;
          if (percents) {
            setPercents(percents.toString())
          }
        }

      } catch (e) {
        console.log(e)
      }
    },

    SaveData: async () => {
      try {
        await validation();

        const { freeDeliverySum, priceToHome, priceToDoor, toRemoteAreas } = form;
        const withoutIds: IRemoteArea[] = [];

        for (let remote of toRemoteAreas) {
          const { price, startRange, name, endRange } = remote;
          withoutIds.push({ name, price, startRange, endRange })
        }

        const data = {
          freeDeliverySum: +freeDeliverySum, priceToDoor: +priceToDoor,
          priceToHome: +priceToHome, toRemoteAreas: withoutIds
        };

        await request(
          `${config.API}/restaurants/${restId?._id}/percents`,
          'PATCH',
          { percents: +percents },
          {
            Authorization: token as string
          }
        );

        await request(
          `${config.API}/delivery/${restId?._id}`,
          'POST',
          data,
          {
            Authorization: token as string,
          }
        );

        window.location.reload();
      } catch (e) {
        toogleIsAlertOpen(errors.length > 0)
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [restId]);

  useEffect(() => {
    Callbacks.loadRestaurantOptions();
  }, [])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper title={Translater.DeliveryPage.title[language.slug]}>
      <Page.Header
        title={Translater.DeliveryPage.title[language.slug]}
        btnTitle={Translater.Buttons.save[language.slug]}
        buttonHandler={Events.buttonHandler}
      />
      {/* Pick Restaurant */}

      {/* <Page.SearchSelect
        data={rests}
        searchField='title'
        setSwitch={Events.selectRestHandler}
        placeholder={Translater.TableTitles.restaurant[language.slug]}
        value={restId ? { label: restId.title, value: restId._id } : null}
      /> */}

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.freeDeliverySum[language.slug]}
      >
        <Form.Input
          name='freeDeliverySum'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          value={form.freeDeliverySum}
          inputHandler={Events.inputHandler}
          type='number'
        />
      </Form.LabelField>

      {/* <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.priceToHome[language.slug]}
      >
        <Form.Input
          name="priceToHome"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          value={form.priceToHome}
          inputHandler={Events.inputHandler}
          type='number'
        />
      </Form.LabelField> */}

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.priceToDoor[language.slug]}
      >
        <Form.Input
          name='priceToDoor'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          value={form.priceToDoor}
          inputHandler={Events.inputHandler}
          type='number'
        />
      </Form.LabelField>

      {/* <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.bonusesToOrders[language.slug]}
      >
        <Form.Input
          value={percents}
          type="number"
          name="percents"
          inputHandler={(e) => setPercents(e.target.value)}
        />
      </Form.LabelField> */}
      {/* 
      <Buttons.DefaultButton
        title={Translater.Buttons.controlPoints[language.slug]}
        buttonHandler={() => setControl(!control)}
        width={280}
      /> */}

      {control && (
        <div>
          <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.price[language.slug]}
          >
            <Form.Input
              name='price'
              inputHandler={Events.inputRemoteAreaHandler}
              value={remoteArea.price}
              type='number'
            />
          </Form.LabelField>

          <Form.LabelField
            fontSize='small'
            label={Translater.Placeholders.endRange[language.slug]}
          >
            <Form.Input
              name='endRange'
              inputHandler={Events.inputRemoteAreaHandler}
              value={remoteArea.endRange}
              type='number'
            />
          </Form.LabelField>

          <Buttons.DefaultButton
            title={Translater.Buttons.add[language.slug]}
            buttonHandler={Events.addRemoteAreaHandler}
            marginBottom={20}
          />

          {form.toRemoteAreas?.map((area, idx) => (
            <Page.Item
              data={area}
              idx={idx}
              fields={['name', 'price']}
              deleteField='name'
              deleteHandler={Events.deleteRemoteAreaHandler}
              notDel={idx == 0}
            />
          ))}
        </div>
      )}

      {/* <Editor
        initialValue={''}
        onEditorChange={() => { }}
      /> */}
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default IndexPage
