import React, { Fragment, useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks/http.hook'
import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'
import moment from 'moment'

const Track: React.FC<ITrackProps> = ({ data, reloadHandler }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { actions: modalActions } = useContext(Contexts.ModalContext)

  const { request } = useHttp()
  const history = useHistory()

  const Events = {
    statusHandler: () => {
      modalActions.SHOW(data as any, 'status')
    },
    deleteHandler: () => {
      if (access.orders?.children?.deleting?.rule === 'yes') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    },
    editHandler: () => {
      console.log('edit')
      if (access.orders?.rule !== 'false') {
        history.push(`/orders/${data._id}`)
      }
    },
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/orders/${data._id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
      <Table.Track 
        type='orders'
        /*data.status == 'new'
        ? { backgroundColor: 'red' }
        : data.status == 'working'
        ? { backgroundColor: 'yellow' }
        : data.status == 'done'
        ? { backgroundColor: 'green' }
        : {} */
        backgroundColor={data.status}
      >
        <Table.Cell text={`${data.increment}`} />
        <Table.Cell
          text={data.user?.name}
          modalContent={
            <Table.ModalContent
              label={Translater.TableTitles.phone[language.slug]}
              text={data.user ? (data.user.phone ? data.user.phone : '-') : '-'}
            />
          }
        />
        <Table.Cell
          text={
            data.delivery.type === 'courier'
              ? Translater.TableTitles.courier[language.slug]
              : Translater.TableTitles.pickup[language.slug]
          }
          modalContent={
            <Fragment>
              <Table.ModalContent
                label={Translater.TableTitles.phone[language.slug]}
                text={data.delivery.phone}
              />
              <Table.ModalContent
                label={Translater.TableTitles.type[language.slug]}
                text={
                  data.delivery.type === 'courier'
                    ? Translater.TableTitles.courier[language.slug]
                    : Translater.TableTitles.pickup[language.slug]
                }
              />

              {data.delivery.address ? (
                <Table.ModalContent
                  label={Translater.TableTitles.address[language.slug]}
                  text={data.delivery.address.formatted_address}
                />
              ) : null}
              <Table.ModalContent
                label={Translater.TableTitles.deliveryPrice[language.slug]}
                text={`${data.deliveringPrice}`}
              />
            </Fragment>
          }
        />
        <Table.Cell
          text={
            data.comment
              ? data.comment
              : Translater.TableTitles.withoutComment[language.slug]
          }
          modalContent={
            <Table.ModalContent
              text={
                data.comment
                  ? data.comment
                  : Translater.TableTitles.withoutComment[language.slug]
              }
            />
          }
        />
        <Table.Cell text={data.restaurant ? data.restaurant.title : '-'} />
        <Table.Cell
          text={Translater.TableTitles.statusTypes[data.status][language.slug]}
          clickHandler={Events.statusHandler}
        />
        <Table.Cell text={`${data.price} грн`} />
        
        <Table.Cell
          text={`${data.paymentType === 'cash' ? 'Наличные' : 'Онлайн'}`}
        />

        <Table.Cell
          date={new Date(data.created as string)}
          format="DD.MM.YYYY HH:MM"
          modalContent={
            <Table.ModalContent
              text={moment(data.created).format("DD.MM.YYYY HH:MM")}
            />
          }
        />

        <Table.Cell
          type='control'
          editButton={access.orders?.rule !== 'false'}
          deleteButton={access.orders?.children?.deleting?.rule === 'yes'}
          editHandler={Events.editHandler}
          deleteHandler={Events.deleteHandler}
        />
      </Table.Track>
  )
}

export default Track
