import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'

import { useHttp } from '../../hooks/http.hook'
import { IStaff, IRole, TStaffDetailForm } from '../../types/items'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import { useValidation } from '../../hooks'
import { TDetailValidationSchema } from './types'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { id } = useParams() as any
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IStaff | null>(null)
  const [isValid, toggleValid] = useState({
    fullname: false,
  })

  const [form, setForm] = useState<TStaffDetailForm>({
    fullname: '',
    role: '',
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      role: {
        condition: !!form.role,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.role[language.slug]}`,
      },

      fullname: {
        condition: form.fullname.length >= 3,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.fullname[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validationSchema, validation } = useValidation(schema)
  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const value = e.target.value
      setForm({ ...form, [name]: value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setRole: (role: string) => {
      setForm({ ...form, role })
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log('createHandler => e', e)
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStaff = await request(
          `${config.API}/admins/${id}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const response = await request(
          `${config.API}/admins/${primary?._id}`,
          'POST',
          { ...form },
          {
            Authorization: token as string,
          }
        )

        if (response) history.push('/staff')
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/admins/${id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        history.push('/staff')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (primary) {
      setForm({
        ...form,
        fullname: primary?.fullname ?? '',
        role: (primary.role as IRole)._id as string,
      })
    }
  }, [primary])

  useEffect(() => {
    if (form.fullname?.length >= 3) toggleValid({ ...isValid, fullname: true })
    else toggleValid({ ...isValid, fullname: false })
  }, [form.fullname])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper title={primary.fullname}>
      <Page.Header
        backButtonTitle={Translater.StaffDetailPage.title[language.slug]}
        backButtonLink='/staff'

      />

      <Buttons.Container
        disabled={loading}
        saveHandler={
          access.admin?.children?.staff?.rule === 'change'
            ? Events.saveHandler
            : false
        }
        deleteHandler={
          access.admin?.children?.staff?.rule === 'change'
            ? Events.deleteHandler
            : false
        }
      />

      <Components.ConfigBlock
        form={form}
        data={primary}
        isValid={validationSchema}
        setRole={Events.setRole}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
      />

      <Form.DateBlock
        updated={primary.updated as string}
        created={primary.created as string}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
