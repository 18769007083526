import React, {
    Fragment,
    useContext,
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'
import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks/http.hook'


const Track: React.FC<ITrackProps> = ({
    data,
    nesting,
    reloadHandler
}) => {

    const history = useHistory()
    const { access } = useContext(Contexts.UserContext)

    const { token } = useContext(Contexts.AuthContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { request } = useHttp()

    const Events = {
        deleteHandler: () => {
            const answer = window.confirm(Translater.Alert.delete[language.slug])
            if (answer) Callbacks.Delete()
        },
        editHandler: () => {
            history.push(`/booking/${data._id}`)
        }
    }

    const Callbacks = {
        Delete: async () => {
            try {
                await request(`${config.API}/booking/${data._id}`, 'DELETE', null, {
                    Authorization: (token as string),
                })

                reloadHandler()
            } catch (e) {
                console.log(e)
            }
        },
    }

    return (
        <Fragment>
            <Table.Track
                type='booking'
            >
                <Table.Cell
                    text={(nesting + 1).toString()}
                />

                <Table.Cell
                    text={data.guests}
                />

                <Table.Cell
                    text={data.phone}
                />

                <Table.Cell
                    text={new Date((data.date as string)).toLocaleString()}
                />

                <Table.Cell
                    text={new Date((data.created as string)).toLocaleDateString()}
                />

                <Table.Cell
                    text={data.restaurant}
                />

                {access.booking?.rule == 'false' && (
                    <Table.Cell
                        deleteButton
                        type='control'
                        deleteHandler={Events.deleteHandler}
                    />
                )}

            </Table.Track>
        </Fragment>
    )
}

export default Track
