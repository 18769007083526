import React, { useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks'
import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'
import { ICity} from '../../../../types/items'

const Track: React.FC<ITrackProps> = ({ data, order, reloadHandler }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()
  const history = useHistory()

  const Events = {
    deleteHandler: () => {
      if (access.orders?.children?.deleting?.rule === 'yes') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    },
    editHandler: () => {
      if (access.orders?.rule !== 'false') {
        history.push(`/restaurants/${data._id}`)
      }
    },
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/restaurants/${data._id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Table.Track type='restaurants'>
      <Table.Cell text={`${order}`} />

      <Table.Cell text={data.title} />

      <Table.Cell text={(data.city as ICity)?.name} />

      <Table.Cell
        text={new Date(data.updated as string).toLocaleDateString()}
      />

      <Table.Cell
        text={new Date(data.created as string).toLocaleDateString()}
      />

      <Table.Cell
        type='control'
        editButton={access.restaurants?.rule !== 'false'}
        deleteButton={access.restaurants?.rule === 'change'}
        editHandler={Events.editHandler}
        deleteHandler={Events.deleteHandler}
      />
    </Table.Track>
  )
}

export default Track
