import React, { Fragment, useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks/http.hook'
import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'
import { DefaultDeserializer } from 'v8'
import moment from 'moment'


const Track: React.FC<ITrackProps> = ({
  data,
  reloadHandler
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()
  const history = useHistory()

  const Events = {
    deleteHandler: () => {
      if (access.orders?.children?.deleting?.rule === 'yes') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    },
    editHandler: () => {
      if (access.orders?.rule !== 'false') {
        history.push(`/archive/${data._id}`)
      }
    }
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/orders/${data._id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Table.Track
      type='archive'
    >
      <Table.Cell
        text={`${data.increment}`}
      />
      
      <Table.Cell
        text={data.user?.name}
        modalContent={
          <Table.ModalContent
            label={Translater.TableTitles?.phone[language.slug]}
            text={data.user?.phone}
          />
        }
      />
      
      <Table.Cell
        text={data.delivery?.name}
        modalContent={
          <Fragment>
            <Table.ModalContent
              label={Translater.TableTitles.phone[language.slug]}
              text={data.delivery.phone}
            />
            <Table.ModalContent
              label={Translater.TableTitles.type[language.slug]}
              text={data.delivery.type === 'courier' ? (
                Translater.TableTitles.courier[language.slug]
              ) : Translater.TableTitles.pickup[language.slug]}
            />

            {data.delivery.address ? (
              <Table.ModalContent
                label={Translater.TableTitles.address[language.slug]}
                text={data.delivery.address.formatted_address}
              />
            ) : null}
            <Table.ModalContent
              label={Translater.TableTitles.deliveryPrice[language.slug]}
              text={`${data.deliveringPrice}`}
            />
          </Fragment>
        }
      />
      
      <Table.Cell
        text={data.comment ? data.comment : Translater.TableTitles.withoutComment[language.slug]}
        modalContent={
          <Table.ModalContent
            text={data.comment ? data.comment : Translater.TableTitles.withoutComment[language.slug]}
          />
        }
      />
      
      <Table.Cell
        text={Translater.TableTitles.statusTypes[data.status][language.slug]}
      />
      
      <Table.Cell
        text={`${data.price} грн`}
      />
      
      <Table.Cell
        text={new Date((data.archived as string)).toLocaleDateString()}
      />

      <Table.Cell 
        text={new Date((data.created as string)).toLocaleDateString()}
      />

      <Table.Cell
        type='control'
        editButton={access.orders?.rule !== 'false'}
        editHandler={Events.editHandler}
      />
    </Table.Track>
  )
}

export default Track
