import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { IOrder } from '../../types/items'
import { useHttp } from '../../hooks/http.hook'
import { useParams } from 'react-router-dom'
import { config, Translater } from '../../config'


const DetailPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IOrder | null>(null)
  const [loader, toogleLoader] = useState<boolean>(true);

  const Events = {
    reloadHandler: () => {
      Callbacks.Fetch()
    }
  }

  const Callbacks = {
    Fetch: async () => {
      toogleLoader(true)
      try {
        const response: IOrder = await request(`${config.API}/orders/${id}`,
          'GET', null, {
          Authorization: (token as string)
        })
        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleLoader(false);
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  if (loading || !primary) return <Page.Preloader />


  return (
    <Page.Wrapper
      title={`${Translater.OrdersDetailPage.title[language.slug]} №${primary.increment}`}
    >
      {console.log('primary', primary)}
      <Page.WaitWrapper
        loader={loader}
        data={primary}
        withoutNoLength
      >
        <Page.Header
          backButtonTitle={Translater.OrdersDetailPage.title[language.slug]}
          backButtonLink='/orders'

        />

        <Components.DetailBlock
          data={primary}
          reloadHandler={Events.reloadHandler}
        />

        <Components.ProductsList
          items={primary.items}
        />
      </Page.WaitWrapper>
    </Page.Wrapper>
  )
}

export default DetailPage
