import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as Page from '../../components/Page'
import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import { Translater } from '../../config'
import { TCreateValidationSchemaOptions, IForm } from './types'
import * as Components from './components'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useValidation } from '../../hooks'
import { useParams } from 'react-router-dom'
import { useHttp } from '../../hooks'
import { config } from '../../config'
import { useHistory } from 'react-router-dom'

const DetailPage: React.FC = () => {
  const { language } = useContext(Contexts.LanguageContext)
  const { id }: any = useParams()
  const { token } = useContext(Contexts.AuthContext)
  const [rest, setRest] = useState<any>({})
  const { access } = useContext(Contexts.UserContext)
  const history = useHistory()
  const [, setAdditions] = useState<IForm[]>()
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
  const [form, setForm] = useState<IForm>({
    name: '',
    price: '0',
    restaurant: [''],
    photo: null,
    weight: 0,
  })
  const [loader, toogleLoader] = useState<boolean>(true)
  const { request } = useHttp()

  const schema = useMemo<TCreateValidationSchemaOptions>(
    () => ({
      name: {
        condition: form.name.length > 2,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${Translater.OptionsForProductsPage.error.name[
          language.slug
          ]
          }`,
      },
      price: {
        condition: Number(form.price) > 0,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${Translater.OptionsForProductsPage.error.price[
          language.slug
          ]
          }`,
      },
      photo: {
        condition: form.photo != null,
        error: `${Translater.OptionsForProductsPage.error.photo[language.slug]
          }`,
      },
      weight: {
        condition: form.weight.toString().length > 0,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                     ${Translater.Placeholders.g[language.slug]}
                    `,
      },
    }),
    [form, language]
  )

  const { validation, errors } = useValidation(schema)

  const Callbacks = {
    getAddition: async () => {
      toogleLoader(true)
      try {
        const Authorization = token as string
        const addition = await request(`${config.API}/addition`, 'GET', null, {
          Authorization,
        })

        setAdditions(addition)

        toogleLoader(false)
      } catch (e) { }
    },
    GetAdditionById: async () => {
      toogleLoader(true)
      try {
        const Authorization = token as string
        const addition = await request(
          `${config.API}/addition/${id}`,
          'GET',
          null,
          {
            Authorization,
          }
        )

        const rests = await request(`${config.API}/restaurants`, 'GET', null, {
          Authorization,
        })

        console.log('add', addition)
        if (addition) {
          setForm({ ...addition })
          for (let rest of rests) {
            if (rest._id == addition?.restaurant) {
              setRest(rest)
            }
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleLoader(false)
      }
    },

    Save: async () => {
      try {
        await validation()

        const data = new FormData()

        data.append('name', form.name)
        data.append('price', form.price)
        form.restaurant.map((rest) => {
          return data.append('restaurant[]', rest)
        })
        if (typeof form.photo != 'string' && form.photo != null) {
          data.append('photo', form.photo)
        }
        data.append('weight', form.weight.toString())

        await request(`${config.API}/addition/${form?._id}`, 'PATCH', data, {
          Authorization: token as string,
        })

        await Events.reloadHandler()
        history.push('/options-for-products');
      } catch (e) {
        toogleIsAlertOpen(errors.length > 0)
        console.log(e)
      }
    },

    Delete: async () => {
      try {
        await request(`${config.API}/addition/${form?._id}`, 'DELETE', null, {
          Authorization: token as string,
        })
      } catch (e) {
        console.log(e)
      } finally {
        history.push(`/options-for-products`)
      }
    },
  }

  const Events = {
    reloadHandler: () => {
      Callbacks.GetAdditionById()
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) {
        Callbacks.Delete()
      }
    },
  }

  useEffect(() => {
    Callbacks.getAddition()
  }, [])

  useEffect(() => {
    Callbacks.GetAdditionById()
  }, [id])

  return (
    <Page.Wrapper
      title={Translater.OptionsForProductsPage.DetailPage[language.slug]}
    >
      <Page.Header
        backButtonTitle={
          Translater.OptionsForProductsPage.DetailPage[language.slug]
        }
        backButtonLink='/options-for-products'

      />

      <Page.WaitWrapper loader={loader} data={form} withoutNoLength>
        <Buttons.Container
          disabled={false}
          saveHandler={
            access.stocks?.rule === 'change' ? Callbacks.Save : false
          }
          deleteHandler={
            access.stocks?.rule === 'change' ? Events.deleteHandler : false
          }
        />

        <Components.ConfigBlock
          form={form}
          setForm={setForm}
          rest={rest}
          setRest={setRest}
        />

        <Snackbar
          open={isAlertOpen}
          autoHideDuration={10000}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={() => toogleIsAlertOpen(false)}
        >
          <Alert severity='error'>
            {errors.map((error) => (
              <p key={`error-item-${error}`}>- {error}</p>
            ))}
          </Alert>
        </Snackbar>
      </Page.WaitWrapper>
    </Page.Wrapper>
  )
}

export default DetailPage
