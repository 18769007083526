import React, { Fragment, useContext, useEffect, useState } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks'
import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'
import { ICategory } from '../../../../types/items'
import * as Components from '../index'
import { format } from 'path'

const Track: React.FC<ITrackProps> = ({ data, order, reloadHandler, subTrack }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const [showChildren, toggleChildren] = useState<boolean>(false)
  const { loading, request } = useHttp()
  const history = useHistory()

  const [price, setPrice] = useState<string>(data.price as string);
  const [weight, setWeight] = useState<string>(data.weight as string);


  const Events = {
    childrenHandler: () => {
      if (!loading) toggleChildren(!showChildren)
    },
    deleteHandler: () => {
      if (access.orders?.children?.deleting?.rule === 'yes') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    },
    editHandler: () => {
      if (access.orders?.rule !== 'false') {
        history.push(`/products/${data._id}`)
      }
    },
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/products/${data._id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (data.isVariated && data.variations.length) {
      setPrice(data.variations[0].priceChange);
    }

  }, [])

  useEffect(() => {
    if (data.isVariated && data.variations.length && data.variations[0].weightChange) {
      setWeight(data.variations[0].weightChange);
    }
  }, [])

  return (
    <Fragment>

      <Table.Track type={'products'} clickHandler={Events.childrenHandler} tableTrack={!subTrack}>
        <Table.Cell type='custom'>
          {!subTrack && (data?.children && data.children.length > 0 ? (
            <img
              alt='Nesting'
              className={`nesting-arrow ${showChildren && 'active'}`}
              src={require('../../../../assets/icons/arrow-bottom.png')}
            />
          ) : (
            <img
              alt='Dow'
              className='nesting-dot'
              src={require('../../../../assets/icons/dot.png')}
            />
          ))}
        </Table.Cell>

        <Table.Cell
          type='image'
          imageURL={`${config.serverURL}${data.photo}`}
        />

        <Table.Cell text={data.name} />

        {data.category ? (
          <Table.Cell
            linkText={(data.category as ICategory).title}
            linkTo={`/categories/${(data.category as ICategory)._id}`}
          />
        ) : (
          <Table.Cell text={Translater.TableTitles.noInfo[language.slug]} />
        )}

        <Table.Cell
          text={
            data.hidden
              ? Translater.TableTitles.hidden.true[language.slug]
              : Translater.TableTitles.hidden.false[language.slug]
          }
        />

        <Table.Cell text={`${price} грн`} />
        <Table.Cell text={`${weight} г`} />




        <Table.Cell
          text={new Date(data.updated as string).toLocaleDateString()}
        />

        <Table.Cell
          text={new Date(data.created as string).toLocaleDateString()}
        />

        <Table.Cell
          type='control'
          editButton={access?.products?.rule !== 'false'}
          deleteButton={access?.products?.rule === 'change'}
          editHandler={Events.editHandler}
          deleteHandler={Events.deleteHandler}
        />
      </Table.Track>
      {data?.children?.[0] && showChildren
        ? data?.children?.map((item: any, index: number) => (
          <Components.Track
            key={item?._id}
            order={index + 1}
            data={item}
            subTrack
            reloadHandler={reloadHandler}
          />
        ))
        : null}
    </Fragment>
  )
}

export default Track
