import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  KeyboardEvent,
} from 'react'

import './../../scss/dragcard.scss'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import { useHttp } from '../../hooks'

import { config, Translater } from '../../config'

import { IPagination, IFilter, TSortBy, IRestaurant } from '../../types/items'


const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, totalCount, request } = useHttp()

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'updated',
    sortOrder: 'desc',
  })
  const [searchValue, setSearchValue] = useState<string>('')

  const [primary, setPrimary] = useState<IRestaurant[] | undefined>([])
  const [currentCard, setCurrentCard] = useState<IRestaurant>()

  const Events = {
    searchHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value)
    },
    pressHandler: (e: KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.Fetch()
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
    sortHandler: (name: TSortBy) => {
      let sortOrder: 'asc' | 'desc'
      if (filter.sortOrder === 'asc') sortOrder = 'desc'
      else sortOrder = 'asc'
      setFilter({
        sortBy: name,
        sortOrder,
      })

      setPagination({ ...pagination, page: 1 })
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    },
    changePositionHandler: async () => {
      Callbacks.ChagePosition()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants/?page=${pagination.page}&perPage=${pagination.perPage}&title=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )
        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    ChagePosition: async () => {
      const restaurants: Array<Object> = []
      primary?.forEach((item) => {
        const { _id, position} = item
        restaurants.push({ _id: _id, position: position })
      })
      try {
        const response = await request(
          `${config.API}/restaurants/updatePositions`,
          'PUT',
          {
            restaurants: restaurants,
          },
          {
            Authorization: token as string,
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  useEffect(() => {
    Callbacks.Fetch()
  }, [filter.sortBy, filter.sortOrder, pagination.page, pagination.perPage])

  if (loading || !primary) return <Page.Preloader />

  // const dragStartHandler = (e: DragEvent, item: IRestaurant) => {
  //   setCurrentCard(item)
  // }
  // const dragLeaveHandler = (e: DragEvent) => {
  //   e.preventDefault()
  // }
  // const dragEndHandler = (e: DragEvent) => {}
  // const dragOverHandler = (e: DragEvent) => {
  //   e.preventDefault()
  // }
  // const dropHandler = (e: DragEvent, item: IRestaurant) => {
  //   e.preventDefault()
  //   setPrimary(
  //     primary.map((i) => {
  //       if (i._id == item._id) {
  //         return { ...i, position: currentCard?.position }
  //       }
  //       if (i._id == currentCard?._id) {
  //         return { ...i, position: item.position }
  //       }
  //       return i
  //     })
  //   )
  // }
  //
  // const sortCard = (a: IRestaurant, b: IRestaurant) => {
  //   if (a.position! > b.position!) {
  //     return 1
  //   } else {
  //     return -1
  //   }
  // }
  return (
    <Page.Wrapper
      footer
      title={Translater.RestaurantsPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.RestaurantsPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.restaurants?.rule === 'change'}
        linkTo='/restaurants/create'
      />

      <Page.SortBar
        searchBar
        filter={filter}
        value={searchValue}
        perPage={pagination.perPage}
        pressHandler={Events.pressHandler}
        inputHandler={Events.searchHandler}
        selectHandler={Events.selectHandler}
      />
      <Components.ChangePosition primary={primary} setPrimary={setPrimary} />

      <Components.Table
        items={primary}
        sortHandler={Events.sortHandler}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo='/restaurants'
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
