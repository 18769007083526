import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import * as Contexts from '../contexts'

import * as Auth from '../pages/Auth'
import * as Home from '../pages/Home'
// import * as Sales from '../pages/Sales'
import * as Users from '../pages/Users'
import * as UsersBirthdays from '../pages/UsersBirthdays'
import * as Roles from '../pages/Roles'
import * as Staff from '../pages/Staff'
import * as Orders from '../pages/Orders'
import * as Errors from '../pages/Errors'
import * as Archive from '../pages/Archive'
import * as Products from '../pages/Products'
import * as Restaurants from '../pages/Restaurants'
import * as Feedback from '../pages/Feedback'
import * as Settings from '../pages/Settings'
import * as Categories from '../pages/Categories'
import * as Stocks from '../pages/Stocks'
import * as Delivery from '../pages/Delivery'
import * as Notifications from '../pages/Notifications'
import * as Booking from '../pages/Booking'
import * as BookingDetails from '../pages/BookingDetails'
import * as Referals from '../pages/Referals'
import * as News from "../pages/News";
import * as OFP from "../pages/OptionsForProducts";

const Router = () => {
  const { access } = useContext(Contexts.UserContext)
  const { modules } = useContext(Contexts.ConfigContext)
  const { isAuthenticated } = useContext(Contexts.AuthContext)

  if (isAuthenticated) {
    return (
      <Switch>
        <Route path='/admin' exact>
          {modules.admin &&
            access.admin?.rule &&
            access.admin?.rule !== 'false' ? (
            <Home.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/orders' exact>
          {modules.orders &&
            access.orders?.rule &&
            access.orders?.rule !== 'false' ? (
            <Orders.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/orders/:id' exact>
          {modules.orders &&
            access.orders?.rule &&
            access.orders?.rule !== 'false' ? (
            <Orders.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/categories' exact>
          {modules.categories &&
            access.categories?.rule &&
            access.categories?.rule !== 'false' ? (
            <Categories.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/categories/create' exact>
          {modules.categories && access.categories?.rule === 'change' ? (
            <Categories.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/categories/:id' exact>
          {modules.categories &&
            access.categories?.rule &&
            access.categories?.rule !== 'false' ? (
            <Categories.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/products' exact>
          {modules.products &&
            access.products?.rule &&
            access.products?.rule !== 'false' ? (
            <Products.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/products/create' exact>
          {modules.products && access.products?.rule === 'change' ? (
            <Products.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/products/:id' exact>
          {modules.products &&
            access.products?.rule &&
            access.products?.rule !== 'false' ? (
            <Products.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/restaurants/' exact>
          {modules.restaurants &&
            access.restaurants?.rule &&
            access.restaurants?.rule !== 'false' ? (
            <Restaurants.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/restaurants/create' exact>
          {modules.restaurants && access.restaurants?.rule === 'change' ? (
            <Restaurants.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>
        <Route path='/restaurants/:id' exact>
          {modules.restaurants &&
            access.restaurants?.rule &&
            access.restaurants?.rule !== 'false' ? (
            <Restaurants.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>
        {/* 
        <Route path='/referals' exact>
          <Referals.IndexPage />
        </Route> */}
        {/* 
        <Route path='/referals/create' exact>
          <Referals.CreatePage />
        </Route>

        <Route path='/referals/:id' exact>
          <Referals.DetailPage />
        </Route> */}

        <Route path='/booking' exact>
          <Booking.IndexPage />
        </Route>
        {/* 
        <Route path='/booking/create' exact>
          <Booking.CreatePage />
        </Route>

        <Route path='/booking/:id' exact>
          <Booking.DetailPage />
        </Route> */}
        {/* 
        <Route path='/bookingDetails' exact>
          <BookingDetails.IndexPage />
        </Route>

        <Route path='/bookingDetails/:id' exact>
          <BookingDetails.DetailPage />
        </Route> */}

        <Route path='/notifications' exact>
          <Notifications.IndexPage />
        </Route>

        <Route path='/delivery' exact>
          <Delivery.IndexPage />
        </Route>

        <Route path='/stocks' exact>
          <Stocks.StocksPage />
        </Route>
        <Route path='/stocks/create' exact>
          <Stocks.CreatePage />
        </Route>
        <Route path='/stocks/:id' exact>
          <Stocks.DetailPage />
        </Route>

        <Route path="/news" exact>
          <News.NewsPage />
        </Route>
        <Route path="/news/create" exact>
          <News.CreatePage />
        </Route>
        <Route path="/news/:id" exact>
          <News.DetailPage />
        </Route>

        {/* <Route path='/feedback' exact>
          {
            (
              (modules.products as any)?.feedback
              && access.products?.children?.feedback?.rule
              && access.products?.children?.feedback?.rule !== 'false')
              ? <Feedback.IndexPage />
              : <Errors.Forbidden />
          }
        </Route>

        <Route path='/feedback/:id' exact>
          {
            (
              (modules.products as any)?.feedback
              && access.products?.children?.feedback?.rule
              && access.products?.children?.feedback?.rule === 'change')
              ? <Feedback.DetailPage />
              : <Errors.Forbidden />
          }
        </Route> */}

        <Route path='/users' exact>
          {modules.users &&
            access.users?.rule &&
            access.users?.rule !== 'false' ? (
            <Users.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/users/:id' exact>
          {modules.users &&
            access.users?.rule &&
            access.users?.rule !== 'false' ? (
            <Users.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        {/* <Route path='/usersBirthdays' exact>
          {modules.users &&
          access.users?.rule &&
          access.users?.rule !== 'false' ? (
            <UsersBirthdays.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route> */}

        <Route path='/staff' exact>
          {(modules.admin as any)?.staff &&
            access.admin?.children?.staff?.rule &&
            access.admin?.children?.staff?.rule !== 'false' ? (
            <Staff.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/staff/create' exact>
          {(modules.admin as any)?.staff &&
            access.admin?.children?.staff?.rule === 'change' ? (
            <Staff.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/staff/:id' exact>
          {(modules.admin as any)?.staff &&
            access.admin?.children?.staff?.rule &&
            access.admin?.children?.staff?.rule !== 'false' ? (
            <Staff.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/roles' exact>
          {(modules.admin as any)?.roles &&
            access.admin?.children?.roles?.rule &&
            access.admin?.children?.roles?.rule !== 'false' ? (
            <Roles.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/roles/create' exact>
          {(modules.admin as any)?.roles &&
            access.admin?.children?.roles?.rule === 'change' ? (
            <Roles.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/roles/:id' exact>
          {(modules.admin as any)?.roles &&
            access.admin?.children?.roles?.rule &&
            access.admin?.children?.roles?.rule !== 'false' ? (
            <Roles.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/archive' exact>
          {(modules.orders as any)?.history &&
            access.orders?.children?.history.rule &&
            access.orders?.children?.history.rule !== 'false' ? (
            <Archive.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path='/archive/:id' exact>
          {(modules?.orders as any)?.history &&
            access.orders?.children?.history.rule &&
            access.orders?.children?.history.rule !== 'false' ? (
            <Archive.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/options-for-products" exact>
          <OFP.OptionsForProducts />
        </Route>

        <Route path="/options-for-products/create" exact>
          <OFP.CreatePage />
        </Route>

        <Route path="/options-for-products/:id" exact>
          <OFP.DetailPage />
        </Route>

        <Route path='/settings' exact>
          <Settings.IndexPage />
        </Route>

        <Redirect to='/admin' />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path='/'>
        <Auth.IndexPage />
      </Route>

      <Redirect to='/' />
    </Switch>
  )
}

export default Router
