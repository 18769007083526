import React, { useContext } from 'react'

import * as Table from '../../../../components/Table'
import * as Components from '..'

import { ITableWrapperProps } from './types'
import * as Contexts from '../../../../contexts'

import { SHomeTitles } from './static'


const TableWrapper: React.FC<ITableWrapperProps> = ({
    items,
    reloadHandler
}) => {




    return (
        <Table.Table
            type='booking'
            titles={SHomeTitles}
        >
            {items.map((item, idx) => (
                <Components.Track
                    key={item._id}
                    data={item}
                    nesting={idx}
                    reloadHandler={reloadHandler}
                />
            ))}
        </Table.Table>
    )
}

export default TableWrapper
