import { TForm } from './types'

export const SInitialForm: TForm = {
  city: '',
  role: '',
  login: '',
  title: '',
  password: '',
  email: '',
  phone: '',
  label: '',
  license: '',
  latitude: '',
  facebook: '',
  longitude: '',
  instagram: '',
  headerColor: '',
}
