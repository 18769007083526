import React from 'react'

import * as Table from '../../../../components/Table'
import * as Components from '..'

import { ITableWrapperProps } from './types'

import { SHomeTitles } from './static'


const TableWrapper: React.FC<ITableWrapperProps> = ({
  items,
  reloadHandler,
    sortHandler
}) => {

  return (
    <Table.Table
      type='categories'
      titles={SHomeTitles}
      sortHandler={sortHandler}
    >
      {items.map(item => (
        <Components.Track
          key={item._id}
          data={item}
          nesting={0}
          reloadHandler={reloadHandler}
        />
      ))}
    </Table.Table>
  )
}

export default TableWrapper
