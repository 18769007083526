import React, {
  useState,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks'
import { useHistory } from 'react-router-dom'
import { IRole } from '../../types/items'
import {
  TMutliLangTitles,
} from '../../types/multiLang'
import { TCreateValidationSchema } from './types'
import { useValidation } from '../../hooks'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [form, setForm] = useState<IRole>({
    title: '',
    rules: {
      admin: {
        rule: 'false',
        children: {
          roles: {
            rule: 'false',
          },
          staff: {
            rule: 'false',
          },
        },
      },
      categories: {
        rule: 'false',
      },
      restaurants: {
        rule: 'false',
      },
      orders: {
        rule: 'false',
        children: {
          history: {
            rule: 'false',
          },
          deleting: {
            rule: 'false',
          },
          processing: {
            rule: 'false',
          },
        },
      },
      products: {
        rule: 'false',
        children: {
          similar: {
            rule: 'false',
          },
          feedback: {
            rule: 'false',
          },
        },
      },
      users: {
        rule: 'false',
      },
    },
  })
  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    'title[EN]': '',
    'title[RU]': '',
    'title[UA]': '',
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      title: {
        condition: form.title.toString().length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.title[language.slug]}`,
      },
    }),
    [form, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: async () => {
      try {
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log(e)
      }
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        const response = await request(
          `${config.API}/roles`,
          'POST',
          { ...form },
          {
            Authorization: token as string,
          }
        )

        if (response.created) {
          history.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Page.Wrapper title={Translater.RoleCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.RoleCreatePage.title[language.slug]}
        backButtonLink='/roles'

      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        form={form}
        isValid={validationSchema}
        multiLangTitles={multiLangTitles}
        setForm={setForm}
        inputHandler={Events.inputHandler}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
