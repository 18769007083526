import React, {
    useState,
    useEffect,
    useContext,
    ChangeEvent,
    useMemo,
} from 'react'

import * as Components from './components'
import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import * as Page from '../../components/Page'

import { SInitialForm } from './static'
import { TForm, TRestaurantValidationSchema } from './types'
import { config, Translater } from '../../config'
import { IRestaurant } from '../../types/items'
import { useHttp } from '../../hooks'
import { useValidation } from '../../hooks'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { IRole } from '../../types/items'
import { useHistory } from "react-router-dom";
import { RegExpCoordinate, RegExpEmail, RegExpLinks, RegExpTel } from "../../utils/regExpForValidation";


const CreatePage: React.FC = () => {
    const { token } = useContext(Contexts.AuthContext)
    const { language } = useContext(Contexts.LanguageContext)

    const history = useHistory()
    const { loading, request } = useHttp()
    const [photo, setPhoto] = useState<File | null>(null)
    const [headerPhoto, setHeaderPhoto] = useState<File | null>(null);
    const [form, setForm] = useState<TForm>(SInitialForm)
    const [headerColor, setHeaderColor] = useState<string>("#000");

    const [roles, setRoles] = useState<IRole[]>([]);

    const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)


    const schema = useMemo<TRestaurantValidationSchema>(
        () => ({
            city: {
                condition: !!form.city,
                error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.city[language.slug]}`,
            },
            role: {
                condition: form.role.length >= 0,
                error: `${Translater.ErrorLength[language.slug]}`,
            },
            login: {
                condition: form.login.length >= 4,
                error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.login[language.slug]}`,
            },
            title: {
                condition: form.title.length >= 3,
                error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
            },
            password: {
                condition: form.password.length >= 3,
                error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
            },
            phone: {
                condition: RegExpTel.test(form.phone),
                error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.phone[language.slug]}`,
            },
            email: {
                condition: RegExpEmail.test(form.email),
                error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.email[language.slug]}`,
            },
            license: {
                condition: form.license.length >= 3,
                error: `
          ${Translater.ErrorField[language.slug]}:
          ${Translater.TableTitles.license[language.slug]}`,
            },
            facebook: {
                condition: RegExpLinks.test(form.facebook),
                error: `
          ${Translater.ErrorLinks[language.slug]}:
          ${Translater.TableTitles.facebook[language.slug]}`,
            },
            instagram: {
                condition: RegExpLinks.test(form.instagram),
                error: `
          ${Translater.ErrorLinks[language.slug]}:
          ${Translater.TableTitles.instagram[language.slug]}`,
            },
            label: {
                condition: form.label.length > 5,
                error: `
          ${Translater.ErrorLabelRestaurants[language.slug]}:
          ${Translater.TableTitles.addressLabel[language.slug]}`,
            },
            latitude: {
                condition: RegExpCoordinate.test(form.latitude),
                error: `
          ${Translater.ErrorTypeNumber[language.slug]}:
          ${Translater.TableTitles.latitude[language.slug]}`,
            },
            longitude: {
                condition: RegExpCoordinate.test(form.longitude),
                error: `
          ${Translater.ErrorTypeNumber[language.slug]}:
          ${Translater.TableTitles.longitude[language.slug]}`,
            },
            headerColor: {
                condition: true,
                error: ``,
            }
        }),
        [form, language]
    )
    const { errors, validationSchema, validation } = useValidation(schema)

    const Events = {
        inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.name === 'photo') {
                setPhoto(e.target?.files?.length ? e.target?.files[0] : null)
            }
            else setForm({ ...form, [e.target.name]: e.target.value })
        },
        inputFileHandler: (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.name === 'headerPhoto') {
                console.log('PHOTOHEader')
                setHeaderPhoto(e.target?.files?.length ? e.target?.files[0] : null)
            }
        },
        selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setForm({ ...form, [e.target.name]: e.target.value })
        },
        setCity: (city: string) => {
            setForm({ ...form, city })
        },
        setRole: (role: string) => {
            setForm({ ...form, role })
        },
        createHandler: async () => {
            try {

                await validation()

                await Callbacks.Create()
            } catch (e) {
                toogleIsAlertOpen(true)
            }
        },
    }

    const Callbacks = {
        GetRoles: async () => {
            const response: IRole[] = await request(
                `${config.API}/roles`,
                'GET',
                null,
                {
                    Authorization: token as string,
                }
            )
            setRoles(response);
        },
        Create: async () => {
            try {
                const {
                    title,
                    city,
                    password,
                    role,
                    login,

                    email,
                    phone,
                    label,
                    license,
                    latitude,
                    facebook,
                    longitude,
                    instagram,
                } = form

                const contacts: IRestaurant['contacts'] = {
                    phone,
                    email,
                    license,
                    social: {
                        facebook,
                        instagram,
                    },
                    address: {
                        label,
                        latitude,
                        longitude,
                    },
                }

                const data: FormData = new FormData()

                data.append('contacts', JSON.stringify(contacts))

                if (photo) data.append('restPhoto', photo)

                headerPhoto && data.append('headerPhoto', headerPhoto)
                data.append('title', title)
                data.append('city', city)
                // data.append('role', role)
                for (let roleItem of roles) {
                    if (roleItem.title === role) {
                        data.append('role', roleItem?._id as string);
                    }
                }
                data.append('headerColor', form.headerColor);
                if (password) data.append('password', password)
                if (login) data.append('login', login)

                const response = await request(
                    `${config.API}/restaurants`,
                    'POST',
                    data,
                    {
                        Authorization: token as string,
                    }
                )

                if (response.title) {
                    history.goBack()
                }
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.GetRoles();
    }, []);

    return (
        <Page.Wrapper title={Translater.RestaurantsCreatePage.title[language.slug]}>
            <Page.Header
                backButtonTitle={Translater.RestaurantsCreatePage.title[language.slug]}
                backButtonLink='/restaurants'

            />

            <Buttons.Container
                disabled={loading}
                createHandler={Events.createHandler}
            />

            <Components.ConfigBlock
                isCreate
                form={form}
                photo={photo}
                headerPhoto={headerPhoto}
                isValid={validationSchema}
                setCity={Events.setCity}
                setRole={Events.setRole}
                inputHandler={Events.inputHandler}
                setHeaderColor={setHeaderColor}
                setForm={setForm}
                inputFileHandler={Events.inputFileHandler}
            />

            <Components.ContactsBlock
                form={form}
                isValid={validationSchema}
                inputHandler={Events.inputHandler}
            />

            <Snackbar
                open={isAlertOpen}
                autoHideDuration={10000}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onClose={() => toogleIsAlertOpen(false)}
            >
                <Alert severity='error'>
                    {errors.map((error) => (
                        <p key={`error-item-${error}`}>- {error}</p>
                    ))}
                </Alert>
            </Snackbar>
        </Page.Wrapper>
    )
}

export default CreatePage
