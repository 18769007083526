import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'
import { config, Translater } from '../../config'

import { IStock } from '../../types/items'
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions,
} from '../../types/multiLang'

import { useHistory } from 'react-router-dom'
import { TCreateValidationSchema } from './types'
import { useValidation } from '../../hooks'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { multiLang, modules } = useContext(Contexts.ConfigContext)

  const { loading, request } = useHttp()
  const history = useHistory()

  const [startDate, setStartDate] = useState(new Date())
  const [showProduct, setShowProduct] = useState(false)

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    percent: 0,
    description: false,
    restaurantsList: true,
  })

  const [form, setForm] = useState<IStock>({
    name: '',
    photo: '',
    percent: 0,
    description: '',
    status: false,
    dateStart: 0,
    dateEnd: 0,
    timeEnd: 0,
    productsList: '',
    categoriesList: '',
    restaurantsList: '',
  })
  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    'title[EN]': '',
    'title[RU]': '',
    'title[UA]': '',
  })
  useEffect(() => {
    console.log('form', form)
  }, [form])

  const [multiLangDescriptions, setMultiLangDescriptions] =
    useState<TMutliLangDescriptions>({
      'description[EN]': '',
      'description[RU]': '',
      'description[UA]': '',
    })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      name: {
        condition: form.name.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },
      photo: {
        condition: !!form.photo,
        error: `
        ${Translater.ErrorPhoto[language.slug]}`,
      },
      percent: {
        condition: form.percent > 0,
        error: `
        ${Translater.ErrorSize[language.slug]}:
        ${Translater.TableTitles.percent[language.slug]}`,
      },
      description: {
        condition: form.description.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
      restaurantsList: {
        condition: (true),
        error: `
        ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.restaurantsList[language.slug]}`,
      },
      categoriesList: {
        condition: form.categoriesList != null,
        error: `
        ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.categoriesList[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      //@ts-ignore
      if (e.target.type === 'file')
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: async () => {
      try {
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log(e)
      }
    },
  }
  //@ts-ignore
  const HandlerShowProduct = () => {
    setShowProduct(true)
  }

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData()
        console.log(data)

        // if (multiLang) {
        //   for (let key in multiLangTitles) {
        //     data.append(key, multiLangTitles[key as TPossibleMultiLangTitle])
        //   }
        // } else data.append('title', form.name)
        console.log("WORK", form.photo);
        data.append('photo', form.photo)
        data.append('name', form.name)
        data.append('description', form.description)
        data.append('percent', form.percent as string)
        data.append('dateStart', form.dateStart as string)
        data.append('dateEnd', form.dateEnd as string)
        console.log('FORM', form);
        data.append('productsList[0]', form.productsList as any)
        data.append('categoriesList[0]', form.categoriesList as any)
        data.append('restaurantsList[0]', form.restaurantsList as any)

        const response = await request(`${config.API}/stocks`, 'POST', data, {
          Authorization: token as string,
        })

        if (response.created) {
          history.goBack()
          setForm({
            ...form,
            name: '',
            description: '',
            photo: '',
            percent: 0,
            status: false,
            dateStart: 0,
            dateEnd: 0,
            timeEnd: 0,
            productsList: '',
            categoriesList: '',
            restaurantsList: '',
          })


        }
      } catch (e) {
        console.log("ERROR", e)
      }
    },
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (
          multiLangTitles[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, name: true, restaurantsList: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, name: false, restaurantsList: false })
        }
      }
    } else {
      if (form.name.length >= 2)
        toggleValid({ ...isValid, name: true, restaurantsList: true })
      else toggleValid({ ...isValid, name: false, restaurantsList: false })
    }
  }, [form.name, form.productsList, multiLangTitles])

  useEffect(() => {
    if (form.photo) toggleValid({ ...isValid, photo: true })
    else toggleValid({ ...isValid, photo: false })
  }, [form.photo])

  useEffect(() => HandlerShowProduct(), [])

  return (
    <Page.Wrapper title={Translater.StocksCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.StocksCreatePage.title[language.slug]}
        backButtonLink="/stocks"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />
      {console.log("FORM", form)}
      <Components.ConfigBlock
        //@ts-ignore
        form={form}
        from={'create'}
        //@ts-ignore
        isValid={validationSchema}
        multiLangValues={multiLangTitles}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        setForm={setForm}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
