import { ITableTitle } from '../../../../types/table'

export const SHomeTitles: ITableTitle[] = [
  { type: 'header', slug: 'increment' },
  { type: 'header', slug: 'photo' },
  { type: 'header', slug: 'title' ,sort:true},
  { type: 'header', slug: 'hidden' , sort:true},
  { type: 'header', slug: 'created' , sort:true},
  { type: 'header', slug: 'updated' , sort:true},
  { type: 'header', slug: '' },
]
