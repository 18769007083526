import React, { useContext } from "react";
import * as Table from "../../../../components/Table";
import { TrackProps } from "./types";
import * as Contexts from "../../../../contexts";
import { Translater } from "../../../../config";

const Track: React.FC<TrackProps> = ({
    data,
    Events,
}) => {
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext);

    return (
        <Table.Track type='options'>
            <Table.Cell   
                text={data?.increment}
            />

            <Table.Cell
                type="image"
                imageURL={data?.photo}
            />

            <Table.Cell  
                text={data?.name}
            />

            <Table.Cell  
                text={data?.price + ' грн'}
            />

            <Table.Cell 
                text={data.restaurant}
            />

            <Table.Cell 
                text={data.weight ? data.weight + 'г' : Translater.TableTitles.noInfo[language.slug]}
            />

            <Table.Cell 
                type='control'
                editButton={access.stocks?.rule !== 'false'}
                deleteButton={access.stocks?.rule === 'change'}
                editHandler={() => Events.editHandler(data._id)}
                deleteHandler={() => Events.deleteHandler(data._id)}
            />
        </Table.Track>
    )
}

export default Track;    