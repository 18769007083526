import React, { useContext, useState, useEffect } from "react";
import * as Page from "../../components/Page";
import { Translater } from "../../config";
import * as Contexts from "../../contexts";
import * as Components from "./components"
import { useHttp } from "../../hooks";
import { config } from "../../config";
import { IPagination, IRestaurant } from "../../types/items";
import * as Form from "../../components/Form"
import { useHistory } from "react-router-dom";
import { IAdditions } from "../../types/items";

const OptionsForProducts: React.FC = () => {
    const { language } = useContext(Contexts.LanguageContext);
    const { token } = useContext(Contexts.AuthContext);
    const { access } = useContext(Contexts.UserContext);
    const [rests, setRests] = useState<IRestaurant[]>([]);
    const [rest, setRest] = useState<IRestaurant | null>(null);
    const [additions, setAdditions] = useState<IAdditions[] | null>(null);
    const [loader, setLoader] = useState<boolean>(true);
    const { request, totalCount } = useHttp();
    const history = useHistory()
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        perPage: 10,
        totalCount: 0,
    })
    const [searchValue, setSearchValue] = useState<string>("");
    const [defaultValue, setDefaultValue] = useState<IAdditions[]>([]);

    const getCompleteConvertArray = (additions: IAdditions[], rest: IRestaurant): IAdditions[] => {
        const Arr: any[] = [];
        for (let addition of additions) {
            rests.forEach((restItem) => {
                let curr = {};
                for (let c of addition.restaurant) {
                    if (typeof c === 'string') {
                        if (c == rest?._id) {
                            curr = c;
                        }
                    } else {
                        //@ts-ignore
                        if (c?._id == rest?._id) {
                            //@ts-ignore
                            curr = c._id;
                        }
                    }
                }
                if (restItem._id == curr) {
                    Arr.push({
                        ...addition,
                        increment: additions.indexOf(addition) + 1,
                        restaurant: restItem.title,
                        photo: `${config.serverURL}${addition.photo}`
                    });
                }
            });
        };
        return Arr;
    };

    const Callbacks = {
        GetRests: async () => {
            const Authorization = token as string;
            const rests: IRestaurant[] = await request(`${config.API}/restaurants`, "GET", null, {
                Authorization
            });
            console.log('GetRests', rests)
            if (rests.length) {
                setRest(rests[0]);
                setRests(rests);
            };
        },
        Fetch: async () => {

            try {
                const Authorization = token as string;

                const additions: IAdditions[] = await request(
                    `${config.API}/addition/byRestaurant/${rest?._id}?page=${pagination.page}&perPage=${pagination.perPage}`, "GET", null, {
                    Authorization
                });
                console.log(additions)
                if (rest) {
                    const Arr = getCompleteConvertArray(additions, rest)
                    setSearchValue("");
                    setDefaultValue(Arr);
                    setAdditions(Arr);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoader(false);
            }
        },

        Delete: async (addition: any) => {
            console.log(addition);
            try {
                await request(`${config.API}/addition/${addition}`, 'DELETE', null, {
                    Authorization: token as string
                });
            } catch (e) {
                console.log(e)
            }
            Callbacks.Fetch();
        },

        Search: async () => {
            try {
                setAdditions([])
                const search = await request(`${config.API}/addition/byRestaurant/${rest?._id}/search?title=${searchValue}`, 'GET', null, {
                    Authorization: token as string
                });
                if (rest) {
                    const Arr = getCompleteConvertArray(search, rest)
                    console.log(search);
                    setAdditions(Arr);
                }
            } catch (e) {
                console.log(e);
            }
        },
    }

    const Events = {
        selectRestHandler: (e: any) => {
            const value = e.target.value;
            for (let rest of rests) {
                if (rest.title == value) {
                    setRest(rest);
                };
            };
        },

        editHandler: (id: string) => {
            history.push(`/options-for-products/${id}`)
        },

        deleteHandler: (addition: string) => {
            const answer = window.confirm(Translater.Alert.delete[language.slug])
            if (answer) {
                Callbacks.Delete(addition);
            }
        },

        reloadHandler: () => {
            Callbacks.Fetch();
        },

        selectHandler: (e: any) => {
            setPagination({ ...pagination, perPage: +e.target.value });
        },

        inputHandler: (e: any) => {
            if (e.target.value == 0) {
                setAdditions(defaultValue);
            }
            setSearchValue(e.target.value);
        },

        pressHandler: (e: any) => {
            if (e.key === "Enter") Callbacks.Search();
        },

        paginateHandler: (page: number) => {
            setPagination({ ...pagination, page });
        },
    }

    useEffect(() => {
        Callbacks.GetRests();
    }, []);

    useEffect(() => {
        if (rests.length) {
            setAdditions([]);
            Callbacks.Fetch()
        }
    }, [rest, rests, pagination.page, pagination.perPage]);

    useEffect(() => {
        setPagination({ ...pagination, totalCount });
    }, [totalCount]);

    return (
        <Page.Wrapper title={Translater.OptionsForProductsPage.title[language.slug]}>
            <Page.Header
                title={Translater.OptionsForProductsPage.title[language.slug]}
                linkTitle={Translater.Buttons.create[language.slug]}
                enableButton={access.restaurants?.rule === 'change'}
                linkTo='/options-for-products/create'
            />

            {/* Pick Restaurant */}

            {/* <Form.DefaultSelect
                options={rests}
                field="title"
                selectHandler={Events.selectRestHandler}
                name="optionsSelect"
                value={rest?.title || ""}
            /> */}
            <Page.SortBar
                searchBar
                value={searchValue}
                perPage={pagination.perPage}
                inputHandler={Events.inputHandler}
                selectHandler={Events.selectHandler}
                pressHandler={Events.pressHandler}
            />
            {additions && (
                <Page.WaitWrapper
                    data={additions.length > 0}
                    loader={loader}
                    no={Translater.OptionsForProductsPage.no[language.slug]}
                >
                    <Components.Table
                        Events={Events}
                        data={additions}
                    />
                </Page.WaitWrapper>
            )}

            <Page.Footer>
                <Page.Pagination
                    pagination={pagination}
                    paginateHandler={Events.paginateHandler}
                    linkTo="/options-for-products"
                    setPagination={setPagination}
                />
            </Page.Footer>
        </Page.Wrapper>
    )
}

export default OptionsForProducts;
