import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'

import { IFieldProps, TPossibleRuleSlugs } from './types'
import { Translater } from '../../../../config'
import { IOption } from '../../../../types/items'

const Field: React.FC<IFieldProps> = ({
  form,
  slug,
  item,
  parent,
  nesting,
  setForm,
}) => {
  const { language } = useContext(Contexts.LanguageContext)

  const [value, setValue] = useState<string>('hide')
  const [options, setOptions] = useState<IOption[]>([])
  const [showChildren, toggleShowChildren] = useState<boolean>(false)

  const Events = {
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      const newValue = e.target.value.split('-')[0]
      setValue(newValue)

      if (!nesting)
        setForm((prev) => ({
          ...prev,
          rules: {
            ...prev.rules,
            [slug]: {
              //@ts-ignore
              ...prev.rules[slug],
              rule: newValue,
            },
          },
        }))
      else if (nesting === 1 && parent) {
        setForm((prev) => ({
          ...prev,
          rules: {
            ...prev.rules,
            [parent]: {
              //@ts-ignore
              ...prev.rules[parent],
              children: {
                //@ts-ignore
                ...prev.rules[parent].children,
                [slug]: { rule: newValue },
              },
            },
          },
        }))
      }
    },
  }

  useEffect(() => {
    if (!nesting && Object.keys(form.rules).length) {
      //@ts-ignore
      if (!form.rules[slug]?.rule) setValue('false')
      else {
        //@ts-ignore
        setValue(form.rules[slug].rule)
      }
    } else if (nesting === 1 && parent && Object.keys(form.rules).length) {
      //@ts-ignore
      if (!form.rules[parent]?.children) setValue('false')
      //@ts-ignore
      else if (!form.rules[parent]?.children[slug]?.rule) setValue('false')
      else {
        //@ts-ignore
        setValue(form.rules[parent].children[slug].rule)
      }
    }
  }, [form.rules, nesting, parent, slug])

  useEffect(() => {
    const arr: IOption[] = [{ value: 'false-false', slug: 'hide' }]

    for (let key in item.rules.values) {
      const childrenRule = item.rules.values[key].children
      arr.push({
        value: key + '-' + (childrenRule ? childrenRule : 'false'),
        slug: key as any,
      })
    }
    setOptions(arr)
  }, [item])

  useEffect(() => {
    const values = item.rules.values
    for (let key in values) {
      if (key === value) {
        const childrenRule = values[key].children
        toggleShowChildren(childrenRule || false)
        break
      } else toggleShowChildren(false)
    }
  }, [item.rules.values, value])
  // console.log(slug)
  return (
    <Fragment>
      {console.log("SLUG", slug)}
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles[slug][language.slug] || ''}
        containerStyle={{
          paddingLeft: nesting,
        }}
      >
        <Form.Select
          name={slug}
          value={value + '-' + showChildren}
          options={options}
          selectHandler={Events.selectHandler}
        />
      </Form.LabelField>

      {showChildren
        ? Object.keys(item.children).map((select) => {
          return (
            <Field
              form={form}
              key={select}
              parent={slug}
              //@ts-ignore
              nesting={nesting + 1}
              item={item.children[select]}
              slug={select as TPossibleRuleSlugs}
              setForm={setForm}
            />
          )
        })
        : null}
    </Fragment>
  )
}

export default Field
