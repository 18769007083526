import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'
import { config, Translater } from '../../config'

import { INewsForm } from './types'
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions,
} from '../../types/multiLang'

import { useHistory } from 'react-router-dom'
import { TCreateValidationSchemaNews } from './types'
import { useValidation } from '../../hooks'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { multiLang, modules } = useContext(Contexts.ConfigContext)

  const { loading, request } = useHttp()
  const history = useHistory()

  const [startDate, setStartDate] = useState(new Date())
  const [showProduct, setShowProduct] = useState(false)

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    percent: 0,
    description: false,
    restaurantsList: true,
  })

  const [form, setForm] = useState<INewsForm>({
    title: '',
    photo: '',
    body: '',
    restaurants: [],
  })

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    'title[EN]': '',
    'title[RU]': '',
    'title[UA]': '',
  })
  useEffect(() => {
    console.log('form', form)
  }, [form])

  const [multiLangDescriptions, setMultiLangDescriptions] =
    useState<TMutliLangDescriptions>({
      'description[EN]': '',
      'description[RU]': '',
      'description[UA]': '',
    })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchemaNews>(
    () => ({
      title: {
        condition: form.title.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },
      photo: {
        condition: !!form.photo,
        error: `
        ${Translater.ErrorPhoto[language.slug]}`,
      },
      body: {
        condition: form.body.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
      restaurant: {
        condition: !!form.restaurants.length,
        error: ` 
        ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.restaurantsList[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      //@ts-ignore
      if (e.target.type === 'file')
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    editorHandler: (body: any, editor: any) => {
      setForm({ ...form, body });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: [e.target.value] })
    },
    createHandler: async () => {
      try {
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log(e)
      }
    },
  }
  //@ts-ignore
  const HandlerShowProduct = () => {
    setShowProduct(true)
  }

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData()


        data.append('title', form.title)
        data.append('photo', form.photo)
        data.append('body', form.body)
        for (var i = 0; i < form.restaurants.length; i++) {
          data.append('restaurants[]', form.restaurants[i]);
        }

        const response = await request(`${config.API}/news`, 'POST', data, {
          Authorization: token as string,
        });

        if (response.created) {
          history.goBack()
          setForm({
            ...form,
            title: '',
            body: '',
            photo: '',
            restaurants: [],
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (
          multiLangTitles[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, name: true, restaurantsList: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, name: false, restaurantsList: false })
        }
      }
    } else {
      if (form.title.length >= 2)
        toggleValid({ ...isValid, name: true, restaurantsList: true })
      else toggleValid({ ...isValid, name: false, restaurantsList: false })
    }
  }, [form.title, multiLangTitles])

  useEffect(() => {
    if (form.photo) toggleValid({ ...isValid, photo: true })
    else toggleValid({ ...isValid, photo: false })
  }, [form.photo])

  useEffect(() => HandlerShowProduct(), [])

  return (
    <Page.Wrapper title={Translater.NewsCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.NewsCreatePage.title[language.slug]}
        backButtonLink="/news"

      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        //@ts-ignore
        form={form}
        //@ts-ignore
        setParentID={() => undefined}
        isValid={validationSchema}
        multiLangValues={multiLangTitles}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        editorHandler={Events.editorHandler}
        setForm={setForm}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
